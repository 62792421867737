import React, { useState, useEffect } from 'react'
import { Text, View, TouchableOpacity, Image, ScrollView, SafeAreaView } from 'react-native'
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from 'expo-linear-gradient';
import styles from './style';
import * as Animatable from 'react-native-animatable'
import { getAuth } from "firebase/auth";
import { collection, query, where, getDocs, getFirestore, and} from "firebase/firestore";
import { useFocusEffect } from '@react-navigation/native';


export default function HomeCasoClinico({ navigation }) {

    const auth = getAuth();
    const user = auth.currentUser;
    const [email, setEmail]= useState("");
    const [nome, setNome] = useState("");
   
    const [retorno, setRetorno] = useState([]);

    const [larg, setLarg] = useState(null);

    setInterval(() => {
        var largura = window.screen.width;
        setLarg(largura);
      }, 100);

    
    useFocusEffect(
        React.useCallback(() => {
            if (user !== null) {
                user.providerData.forEach((profile) => {
                    setEmail(profile.email)
                });
            } 
        }, [])
      );

    async function Caso1() {
                  
        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("numeroCaso", "==", '1'), where("email", "==", email), where("status", "!=", "Finalizado"), where("ifoUsuario.diplo", "==", "DAO"))
        let array = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                status: doc.data().status,
                Anamnesis: doc.data().Anamnesis,
                CefalometriaPaciente: doc.data().CefalometriaPaciente,
                DocPaciente: doc.data().DocPaciente
            };
            array.push(Obj)
        });

        setRetorno(array)
}


async function GetNome() {
                  
    const db = getFirestore();
    const citiesRef = collection(db, "Usuarios");
    const q = query(citiesRef, where("email", "==", email));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        setNome(doc.data().nome)
    });

}

 useEffect(() => {
    Caso1();
}, [email])

useEffect(() => {
    GetNome();
}, [email])


    return (

        <LinearGradient style={styles.container}
        colors={['#262626', '#0D0D0D']}>
        <SafeAreaView>
            
            <View style={styles.header}>
                <View>
                    <Text style={styles.Title}>Casos Clínicos</Text>
            </View>

                <TouchableOpacity style={styles.buttomMenu} onPress={() => navigation.goBack()} >
                    <MaterialCommunityIcons
                        name="reply"
                        size={55}
                        color="#ffffff"
                        style={{justifyContent: 'center', alignContent: 'center' }}
                        />
                </TouchableOpacity>
                
            </View>


        <View style={styles.divImg}>
            <Image 
                style={styles.img} source={{
                uri: 'https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FVector.svg?alt=media&token=228c879f-fea2-4636-bb89-6deb936c2a12',
            }} />
        </View>

        <ScrollView style={{flex: 1}}>
            <View style={{flex: 1, justifyContent: "center", alignItems: "center"}}>            
                <Text style={larg <= 500 ? styles.textAp2 : styles.textAp }>
                Hola mi Doc. {nome}, que alegría que quieres programar una sesión individial del Diplomado Avanzado - DAO.{"\n\n"}
                El primer paso para programar tu mentoría personalizada es enviar la 
                documentación del paciente para planificación. Luego, la documentación será 
                analisada por nuestros profesores y si aprobada, recibirás en tu correo 
                electrónico el enlace para programar la sesión a la fecha y hora que mejor te 
                conviene.{"\n\n"}
                Si la documentación es reprobada, recibiras la orientación para cambiar lo que 
                sea necesario y volver a enviar el archivo.{"\n\n"}
                Recuerde que la sesión tiene una duración de 45 minutos y el analisis previo de 
                la documentación se hace necesario para que el tiempo sea provechoso y los 
                casos planificados con precisión.{"\n\n"}
                Por el tiempo le recomendamos que envíe como <Text style={{fontWeight: "bold"}}>máximo 2 casos clínicos</Text>, para que las orientaciones sean hechas 
                con calidad dentro del tiempo disponible. Haga clic en el botón de abajo para enviar la documentación de los pacientes.{"\n\n"}
                Haga clic en el botón de abajo para enviar la documentación para tu próxima 
                sesión individual
                </Text>
            </View>
                <View>
                    <View style={larg <= 500 ? styles.alinhadorMenu2 : styles.alinhadorMenu}>

                        {retorno.length === 0 ?
                            <TouchableOpacity style={styles.Caso}  onPress={() => navigation.navigate("Caso1DAO")}>
                                <Text style={styles.MenuModulos}>🦷 Caso clínico</Text>
                            </TouchableOpacity>

                            :
                            retorno.map((item) => {
                                return(
                                <>
                                    { item.status === "Esperando validación de soporte para dao" &&
                                        <TouchableOpacity style={styles.CasoAnalise}  onPress={() => navigation.navigate("ConsultaCaso1DAO")}>
                                            <Text style={styles.MenuModulosReprovado}>🦷 Caso clínico</Text>
                                        </TouchableOpacity>
                                    }

                                    { item.status === "Esperando análisis de los profesores para dao" &&
                                        <TouchableOpacity style={styles.CasoAnalise}  onPress={() => navigation.navigate("ConsultaCaso1DAO")}>
                                            <Text style={styles.MenuModulosReprovado}>🦷 Caso clínico</Text>
                                        </TouchableOpacity>
                                    }

                                    { item.status === "Reprobado por el soporte" &&
                                        <TouchableOpacity style={styles.CasoReprovado}  onPress={() => navigation.navigate("ConsultaCaso1DAO")}>
                                            <Text style={styles.MenuModulosReprovado}>🦷 Caso clínico</Text>
                                        </TouchableOpacity>
                                    }

                                    { item.status === "Reprobado por los profesores" &&
                                        <TouchableOpacity style={styles.CasoReprovado}  onPress={() => navigation.navigate("ConsultaCaso1DAO")}>
                                            <Text style={styles.MenuModulosReprovado}>🦷 Caso clínico</Text>
                                        </TouchableOpacity>
                                    }
                                </>
                               
                            )})
                            
                        }


                    </View>

                </View>
            </ScrollView>
            </SafeAreaView>
        </LinearGradient>
    )
}